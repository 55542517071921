export const sendAnalytics = (name: string, params?: Object) => {
  // @ts-ignore
  if (window.gtag) {
    if (params) {
      // @ts-ignore
      window.gtag('event', name, { ...params })
    } else {
      // @ts-ignore
      window.gtag('event', name)
    }
  }
}