export const getUrl = () => {
    return process.env.REACT_APP_API_URL
}

export const fetchData = async (route: string, options: any = {}) => {

    const url = getUrl() + route;

    try {
        const response = await fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': !options.file ?
                        'application/json' : 'multipart/form-data'
                },
                body: JSON.stringify(options),
            }
        );

        const result = await response.json();
        if (result.status !== 'ok') {
            return { result: null, error: result.payload };
        }

        return { result: result.payload, error: null };
    } catch (error) {
        return { result: null, error: error };
    }
}