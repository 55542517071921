import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { tonConnectUI, wallet } from '../utils/connect'

export const About: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <button
        type="button"
        className="btn inline-block"
        cy-data="go-back-button"
        onClick={() => navigate('/')}
      >
        Go back
      </button>
    </Fragment>
  )
}
