import React, { FormEvent, useEffect, useRef, useState } from 'react'

const Confirmaiton: React.FC<{ fetcher: Function, link?: string | null, invoiceId: string | null, status: 'not_started' | 'waiting' | 'success' | 'error' }> = (props) => {

  const [ticker, setTicker] = useState(1)

  useEffect(() => {
    if (props.status === 'success' || props.status === 'error') return

    const timerId = setInterval(() => {
      if (ticker === 1) {
        props.fetcher()
      } 
      setTicker(value => value === 5 ? 1 : value+1)
      console.log(ticker)
    }, 1000);

    return () => clearInterval(timerId);
}, [ticker]);

  return (
    <>
      <div className='w-full flex flex-col gap-4 items-center'>
        <div className='font-bold text-white text-xl'>
          {`Invoice id: ${props.invoiceId}`}
        </div>
        {
          props.link && props.status === 'waiting' ? 
          <a href={props.link} className='font-bold text-white text-xl' target='_blank'>
            Open Cryptobot
          </a> : null
        }
        <div className='flex flex-row w-full h-5 justify-center gap-2 items-center'>
          {
            props.status === 'not_started' || props.status === 'waiting' ? <div className='loader' /> : null
          }
          {
            props.status === 'not_started' ?
            <div className='font-semibold'>
              Updating status
            </div> : null
          }
          {
            props.status === 'waiting' ?
            <div className='font-semibold'>
              In process
            </div> : null
          }
          {
            props.status === 'error' ?
            <div className='font-semibold text-red-500'>
              ❌ Transaction cancelled
            </div> : null
          }
          {
            props.status === 'success' ?
            <div className='font-semibold text-green-500'>
              ✅ Payment received
            </div> : null
          }
        </div>
      </div>

    </>
  )
}

export default Confirmaiton