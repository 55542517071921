import { createRoot } from 'react-dom/client'
import {NextUIProvider} from "@nextui-org/react";
// import './utils/connect'
import './index.css'

import App from './App'
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container!)

root.render(
  <NextUIProvider className="h-full">
    <main className="dark text-foreground h-full">
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </main>
  </NextUIProvider>
)
