import React, { FormEvent, useEffect, useRef, useState } from 'react'
import Icon24Close from '../../assets/Icon24Close'
import { Button } from '@nextui-org/react'
import { useLocation } from 'react-router-dom'
import BackButton from '../Panel/BackButton'

const max = 10000
const min = 0.05

const Amount: React.FC<{
  profile: {
    id: number
    name: string | null
    avatar: string | null
    premium: boolean
  } | null
  fetcher: Function
  exchangeRate: number
  goBack: Function
}> = ({ profile, fetcher, exchangeRate, goBack }) => {

  const [inFocus, setInFocus] = useState<null | 'usdt' | 'cbc'>(null)
  const usdtRef = useRef<HTMLInputElement>(null)
  const cbcRef = useRef<HTMLInputElement>(null)
  const [invalidVal, setInvalidVal] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const [currentUSDT, setCurrentUSDT] = useState<string | null>(null)
  const [currentTTC, setCurrentTTC] = useState<string | null>(null)
  
  const handleInput = (newValue: HTMLInputElement | { value: string, validity : { valid: boolean } }, currency: 'usdt' | 'cbc') => {
    if (!profile || !exchangeRate) return
    if (!newValue.value) {
      setCurrentUSDT(null)
      setCurrentTTC(null)
      return
    }
    if (newValue.value.endsWith(',')) {
      newValue.value = newValue.value.replace(',', '.')
    }
    if (!newValue.validity.valid || Number.isNaN(parseInt(newValue.value))) return;

    if (/^0\.?[0]*$/.test(newValue.value)) {
      const allZeros = /^[0]{2,}$/.test(newValue.value)
      setCurrentUSDT(allZeros ? '0' : newValue.value)
      setCurrentTTC(allZeros ? '0' : newValue.value)
      return
    }

    const value: number = Number(newValue.value)
    if (currency === 'usdt' && (value > max || value < min)) return;
    if (currency === 'cbc' && (value * exchangeRate > max || value * exchangeRate < min)) return;
    const usdt = +Math.round((currency === 'usdt' ? value : value * exchangeRate + Number.EPSILON) * 10e6) / 10e6
    const ttc = +Math.round((currency === 'cbc' ? value : value / exchangeRate + Number.EPSILON) * 10e6) / 10e6
    const addDot = newValue.value.endsWith('.') ? '.' : ''
    setCurrentUSDT(usdt + (currency === 'usdt' ? addDot : ''))
    setCurrentTTC(ttc + (currency === 'cbc' ? addDot : ''))
  }
  
  useEffect(() => {
    const local = localStorage.getItem('amount')
    if (local !== null) {
      handleInput({ value: local, validity: { valid: true }}, 'usdt')
    }
  }, [])

  const exchange = async (e?: FormEvent) => {
    e && e.preventDefault()
    setInFocus(null)
    let flag = true

    if (!currentUSDT || currentUSDT === '0' || Number(currentUSDT) < min || Number(currentUSDT) > max) {
      flag = false
      setInvalidVal(true)
      console.log('invalid value')
    }

    if (!flag) return

    setLoading(true)
    await fetcher(Number(currentUSDT))
    setLoading(false)

  }

  return (
    <>

      {
        profile ?
        <>
          <form className='input w-full flex flex-col' onSubmit={(event) => exchange(event)}>
            <div className="flex flex-col gap-6 w-full">
              <div className="Exchange--USDT relative">
                <input
                  ref={usdtRef}
                  type='tel'
                  pattern="(\d*[\.\,]\d{1,7}|\d{1,7}[\.\,]\d*|\d{1,7})"
                  id="USDT"
                  className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent ${inFocus === 'usdt' ? 'focus:border-[#4F3CC8]' : ''} ${invalidVal ? 'border-red-500' : 'border-white/10'}`}
                  value={currentUSDT ?? "0"}
                  onChange={e => {
                    handleInput(e.target, 'usdt')
                    setInvalidVal(false)
                  }}
                  onFocus={() => setInFocus('usdt')}
                  onBlur={() => inFocus === 'usdt' && setInFocus(null)}
                  required
                />
                <p className={`bg-[#080A23] px-1 font-semibold absolute top-[-10px] left-6 ${inFocus === 'usdt' ? 'text-[#4F3CC8]' : (invalidVal ? 'text-red-500' : 'text-[#4B526A]')}`}>
                  Sent USD
                </p>
                {
                  currentUSDT && currentUSDT !== '0' && inFocus !== 'cbc' ?
                  <div 
                    className="absolute right-4 top-2.5 cursor-pointer"
                    onClick={() => {
                      setInvalidVal(false)
                      setCurrentUSDT('0')
                      setCurrentTTC('0')
                    }}
                  >
                    <Icon24Close />
                  </div> : null 
                }
              </div>
              <div className="Exchange--USDT relative">
                <input 
                  ref={cbcRef}
                  type='tel'
                  pattern="(\d*[\.\,]\d+|\d+[\.\,]\d*|\d+)"
                  id="TTC"
                  className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent ${inFocus === 'cbc' ? 'focus:border-[#4F3CC8]' : ''} ${invalidVal ? 'border-red-500' : 'border-white/10'}`}
                  value={currentTTC ?? "0"}
                  onChange={e => {
                    handleInput(e.target, 'cbc')
                    setInvalidVal(false)
                  }}
                  onFocus={() => setInFocus('cbc')}
                  onBlur={() => inFocus === 'cbc' && setInFocus(null)}
                  required
                />
                <p className={`bg-[#080A23] px-1 font-semibold absolute top-[-10px] left-6 ${inFocus === 'cbc' ? 'text-[#4F3CC8]' : (invalidVal ? 'text-red-500' : 'text-[#4B526A]')}`}>
                  Received CBC
                </p>
              </div>
            </div>
            <div className="h-7 mt-1">
              {
                invalidVal ? <div className='text-red-500 text-sm font-bold text-center'>
                  Enter correct values
                </div> : null
              }
            </div>
            <div className='w-full flex flex-col gap-2'>
              <Button 
                  className='bg-[var(--link\_color)] w-full h-fit rounded-lg py-3 flex gap-2 items-center justify-center !outline-none'
                  onClick={() => {}}
                  isDisabled={Number(currentUSDT ?? 0) === 0}
                  type='submit'
              >
                {
                  loading ? <div className='loader' /> : null
                }
                <div className='font-semibold text-lg'>
                  Proceed to methods
                </div>
              </Button>
              <BackButton 
                onClick={goBack}
              />
            </div>
          </form>
        </> : null
      }
    </>
  )
}

export default Amount