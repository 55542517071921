import React from "react";
import Icon48Users from "../../assets/Icon48Users";
import Icon22Premium from "../../assets/Icon22Premium";

const Profile: React.FC<{
  profile: {
    id: number
    name: string | null
    avatar: string | null
    premium: boolean
  } | null}> = ({ profile }) => {
  return (
    profile ?
      <div className='flex gap-3 w-full'>
        <div className={[
            "relative w-[56px] h-[56px] rounded-lg",
            profile.premium ? "RainbowOutline" : (profile.avatar === null ? 'border-2 border-[#4F3CC8]' : '')
        ].join(' ')}>
            {
            profile.avatar === null ?
            <div className="w-[52px] h-[52px] flex items-center justify-center">
                <Icon48Users/> 
            </div> :
            <img width={profile.premium ? 52 : 56} height={profile.premium ? 52 : 56} className="LeagueUser--image object-contain h-full rounded-lg" src={profile.avatar} alt="" />
            }
            {
              profile.premium ? 
              <div className='absolute top-[-0.25rem] left-[-0.25rem]'>
                  <Icon22Premium />
              </div> : null
            }
        </div>
        <div className='flex flex-col w-full h-fit gap-1'>
            <div className='text-sm text-[var(--secondary\_hint)]'>
                Nickname
            </div>
            <div className='flex w-fit items-baseline'>
                <p className='text-white text-[28px]/8 font-black'>
                  {profile.name ?? 'Anonymous'}
                </p>
            </div>
        </div>
      </div>
      :
      <div className='w-full flex flex-col'>
        <div className='text-red-500 text-sm font-bold text-center'>
          Profile not loaded
        </div>
      </div> 
  )
}

export default Profile