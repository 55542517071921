import { Button } from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC<{ onClick: Function }> = (props) => {
  const navigate = useNavigate()

  return (
    <Button 
      className='text-[var(--link\_color)] bg-white w-full h-fit rounded-lg py-3 flex gap-2 items-center justify-center !outline-none'
      onClick={() => props.onClick()}
      type='button'
    >
      <div className='font-semibold text-lg'>
        Go back
      </div>
    </Button>
  )
}

export default BackButton