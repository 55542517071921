import React, { FormEvent, useEffect, useRef, useState } from 'react'
import Icon24Close from '../../assets/Icon24Close'
import { Button } from '@nextui-org/react'
import Icon24ChevronRight from '../../assets/Icon24ChevronRight'
import { useLocation } from 'react-router-dom'
import { delay } from '../../utils/utils'

const Login: React.FC<{ fetcher: Function }> = (props) => {

  const [name, setName] = useState('')
  const [inFocus, setInFocus] = useState<null | 'login'>(null)
  const loginRef = useRef<HTMLInputElement>(null)
  const [invalidVal, setInvalidVal] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.search !== '' && location.search.includes('name=') && !location.search.includes('&')) {
      const search = location.search.split('?')
      if (search.length > 1) {
        const newName = search.find(item => item.includes('name='))
        if (newName && /^\d{1,15}$/.test(newName.split('=')[1])) {
          setName(newName.split('=')[1])
          login(newName.split('=')[1])
        }
      }
    } else {
      const local = localStorage.getItem('name')
      if (local !== null) {
        setName(local)
      }
    }
  }, [])
  
  const handleInput = (newValue: HTMLInputElement) => {
    if (!newValue.value) {
      setName('')
      return
    }
    if (!newValue.validity.valid || Number.isNaN(parseInt(newValue.value))) return;
    setName(newValue.value)
  }

  const login = async (value: string, e?: FormEvent) => {
    e && e.preventDefault()
    setInFocus(null)
    let flag = true

    if (!/^\d{1,15}$/.test(value)) {
      flag = false
      setInvalidVal(true)
      console.log('invalid value')
    }

    if (!flag) return

    setLoading(true)
    const response = await props.fetcher(value)
    if (!response) {
      setNotFound(true)
    }

    setLoading(false)
  }

  return (
    <>
      <form className='input w-full flex flex-col' onSubmit={(event) => login(name, event)}>
        <div className="relative">
          <input 
            ref={loginRef}
            type="text" 
            pattern='(^\d{1,15}$)'
            className={`w-full border-2 border-solid outline-none px-4 py-3 rounded-2xl bg-transparent ${inFocus === 'login' ? 'focus:border-[#4F3CC8]' : ''} ${invalidVal ? 'border-red-500' : 'border-white/10'}`}
            value={name ?? ""}
            onChange={e => {
              handleInput(e.target)
              setInvalidVal(false)
              setNotFound(false)
            }}
            onFocus={() => {
              setNotFound(false)
              setInFocus('login')
            }}
            onBlur={() => setInFocus(null)}
            required
          />
          <p
            className={`bg-[#080A23] px-1 font-semibold absolute top-[-10px] left-6 ${inFocus === 'login' ? 'text-[#4F3CC8]' : (invalidVal ? 'text-red-500' : 'text-[#4B526A]')}`}
          >
            Telegram ID
          </p>
          {
            name !== '' ?
              <div
                className="absolute right-4 top-2.5 cursor-pointer"
                onClick={() => {
                  setNotFound(false)
                  setName('')
                }}
              >
                <Icon24Close/>
              </div> : null
          }
        </div>
        <div className="h-7 mt-1">
          {
            notFound ? <div className='text-red-500 text-sm font-bold text-center'>
              ID not found
            </div> :
            <div className='text-center'>
              <a 
                className='text-[var(--link\_color)] text-sm font-bold'
                href={process.env.REACT_APP_GET_ID_URL}
              >
                How to get ID?
              </a>
            </div>
          }
        </div>
        <Button 
          className='bg-[var(--link\_color)] mt-1 w-full h-fit rounded-lg py-3 flex gap-2 items-center justify-center !outline-none'
          onClick={() => {}}
          isDisabled={name === ''}
          type='submit'
        >
          {
            loading ? <div className='loader' /> : null
          }
          <div className='font-semibold text-lg'>
              Check ID
          </div>
        </Button>
      </form>

    </>
  )
}

export default Login