import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Counter from '../components/counter/Counter'
import { Tabs, Tab } from '@nextui-org/react'
import Login from '../components/Login/Login'
import { delay } from '../utils/utils'
import Amount from '../components/Amount/Amount'
import Profile from '../components/Profile/Profile'
import Panel from '../components/Panel/Panel'
import Title from '../components/Title/Title'
import Payment from '../components/Payment/Payment'
import { fetchData } from '../utils/api'
import { tonConnectUI, wallet } from '../utils/connect'
import Confirmaiton from '../components/Confirmation/Confirmation'
import { useLocation, useNavigate } from 'react-router-dom'
import BackButton from '../components/Panel/BackButton'
import { sendAnalytics } from '../utils/gtag'

export const Home: React.FC = () => {

  const [tonRate, setTonRate] = useState(6.23)
  const [selectedCurr, setSelectedCurr] = useState('login')
  const [usdt, setUsdt] = useState(0)
  const [status, setStatus] = useState<'not_started' | 'waiting' | 'success' | 'error'>('not_started')
  const [invoiceId, setInvoiceId] = useState<string | null>(null)
  const [botLink, setBotLink] = useState<string | null>(null)

  const [error, setError] = useState<null | string>(null)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    sendAnalytics('token_opened')

    loadRates()

    if (location.search !== '' && location.search.includes('invoiceId=') && !location.search.includes('&')) {
      const search = location.search.split('?')
      if (search.length > 1) {
        const newName = search.find(item => item.includes('invoiceId='))
        if (newName) {
          setInvoiceId(newName.split('=')[1])
          setSelectedCurr('confirmation')
          setStatus('waiting')

          const botLink = localStorage.getItem('botLink')
          if (botLink) {
            console.log(botLink)
            const linkObj = JSON.parse(botLink)
            if (newName.split('=')[1] in linkObj){
              console.log(linkObj)

              setBotLink(linkObj[newName.split('=')[1]])
            }
          }
        }
      }
    }
  }, [])

  const [profile, setProfile] = useState<
    {
      id: number
      name: string | null
      avatar: string | null
      premium: boolean
    } | null
  >(null)

  const loadRates = async () => {
    const tonRate = await fetchData('/get_rate', { currency: 'TON'})
    if (!tonRate.result) return false
    setTonRate(Number(tonRate.result.rate))
    return true
  }

  const fetchProfile = async (id: string) => {
    const res = await fetchData('/user_exists', { tgId: id })
    if (res.result) {
      const result = res.result
      setProfile({
        id: Number(id),
        name: (result.firstName || result.lastName) ? (result.firstName + result.lastName) : null,
        avatar: result.avatar ? process.env.REACT_APP_AVATAR_URL + result.avatar : null,
        premium: false
      })
      localStorage.setItem('name', id)
      sendAnalytics('token_login', { login: id })
      setSelectedCurr('amount')
      navigate('/')
    }
    return res.result
  }

  const setAmount = async (amount: number) => {
    const res = await loadRates()
    if (res) {
      setUsdt(amount)
      localStorage.setItem('amount', amount.toString())
      setSelectedCurr('method')
    } else {
      setError("Error while loading TON rate")
    }
    return res
  }

  const openLink = (url: string) => {
    const ua = navigator.userAgent.toLowerCase()
    if ((ua.includes('safari') || ua.includes('iphone os')) && !ua.includes('chrome')) {
      window.location.assign(url)
    }
    else {
      window.open(url, '_blank')
    }
  }

  const openPaymentMethod = async (option: string, currency: 'usdt' | 'ton') => {

    const name = localStorage.getItem('name')

    if (!name) {
      setStatus('error')
      return { success: false, error: 'Id not provided (relogin)'}
    }

    switch (option) {
      case 'cryptobot': {
        const response = await fetchData('/create_invoice_via_cryptobot', {
          currency,
          cost: currency === 'usdt' ? usdt : (usdt / tonRate),
          tgId: name
        });

        console.log(response)

        if (response.error) {
          return { success: false, error: 'Error while creating invoice'}
        }

        const result = response.result;

        setInvoiceId(result.payload)
        setStatus('waiting')
        setSelectedCurr('confirmation')
        navigate('/?invoiceId=' + result.payload, { replace: true })
        sendAnalytics('token_invoice_created', { type: 'cryptobot', currency, cost: currency === 'usdt' ? usdt : (usdt / tonRate) })

        if (result) {
          setBotLink(result.pay_url)
          localStorage.setItem('botLink', JSON.stringify({ [result.payload]: result.pay_url }))
          openLink(result.pay_url)
        }
        return { success: true, error: null }
      }
      case 'ton': {
        const wallets = await tonConnectUI.getWallets()

        if (!wallets || !wallet) {
          try {
            await tonConnectUI.connectWallet()
          }
          catch (error: any) {
            console.log(error)
            return { success: false, error: 'Error when connecting wallet' }
          }
        }

        const response = await fetchData('/create_invoice_via_tonconnect', {
          tgId: name,
          cost: usdt / tonRate
        });

        console.log(response)

        if (response.error) {
          return { success: false, error: 'Error while creating invoice'}
        }

        const result = response.result;

        setInvoiceId(result.invoice.id)
        setStatus('waiting')
        setSelectedCurr('confirmation')
        navigate('/?invoiceId=' + result.invoice.id, { replace: true })
        sendAnalytics('token_invoice_created', { type: 'tonconnect', currency: 'ton', cost: usdt / tonRate })

        if (result && result.tonTransaction) {

          tonConnectUI.sendTransaction(result.tonTransaction)

        }
        return { success: true, error: null}
      }
      default: return { success: false, error: 'Method doesn\'t exist'}
    }
  }

  const refetcher = async () => {
    if (!invoiceId) return

    const res = await fetchData('/get_invoice_status', {
      id: invoiceId
    });
    if (res.result) {
      switch (res.result) {
        case 'PENDING': {
          setStatus('waiting')
          break
        }
        case 'PAID': {
          setStatus('success')
          localStorage.removeItem('botLink')
          sendAnalytics('token_purchase_confirmed')
          break
        }
        case 'REJECTED': {
          setStatus('error')
          localStorage.removeItem('botLink')
          sendAnalytics('token_purchase_rejected')
          break
        }
        default: setStatus('not_started')
      }
    } else {
      setError('Invoice not found')
      setStatus('error')
    }
  }

  const loading = useMemo(() => {
    return (tonRate) === 0
  }, [tonRate])


  return (
    <div className='flex flex-col w-full justify-center max-w-[400px]'>
      <div className='w-full flex justify-center'>
        <div className='w-24 h-24 self-center'>
            <img className='w-full object-contain' src={require('../assets/crown.png')} alt="" />
        </div>
      </div>
      <div className='text-center w-full text-2xl text-white tracking-tight'>
        Crypto Bunny
      </div>
      <div className='relative w-full h-fit p-4'>
        <Tabs
          aria-label='top-up_form'
          classNames={{
            base: "w-full hidden",
            tabList: "w-full rounded-[44px] px-3 py-[2px] bg-[#080A23]",
            tab: "w-1/3 px-0 h-fit py-0 data-[selected=false]:bg-[#080A23] data-[selected=true]:bg-[#19192B]",
            cursor: "bg-[#19192B] p-1 rounded-b-none rounded-t-lg",
            panel: "px-0 p-4 border-2 border-[#4B526A] rounded-xl transition-all"
          }}
          onSelectionChange={(key) => setSelectedCurr(key as string)}
          selectedKey={selectedCurr}
        >
          <Tab
            key='login'
            title={'login'}
          >
            <Panel>
              <Title 
                title='Enter Telegram ID'
              />
              <Login 
                fetcher={fetchProfile}
              />
            </Panel>
          </Tab>
          <Tab
            key='amount'
            title={'amount'}
          >
            <Panel>
              <Title 
                title='CBC purchase'
              />
              <Profile 
                profile={profile}
              />
              <Amount 
                profile={profile}
                fetcher={setAmount}
                exchangeRate={0.05}
                goBack={() => setSelectedCurr('login')}
              />
            </Panel>
          </Tab>
          <Tab
            key='method'
            title={'method'}
          >
            <Panel>
              <Title 
                title='Payment method'
              />
              <Profile 
                profile={profile}
              />
              <Payment 
                profile={profile}
                fetcher={openPaymentMethod}
                tonRate={tonRate}
                usdt={usdt}
                cbc={+(usdt * 0.05).toPrecision(2)}
              />
              <BackButton 
                onClick={() => setSelectedCurr('amount')}
              />
            </Panel>
          </Tab>
          <Tab
            key='confirmation'
            title={'confirmation'}
          >
            <Panel>
              <Title 
                title='Processing payment'
              />
              <Confirmaiton 
                status={status}
                invoiceId={invoiceId}
                fetcher={() => refetcher()}
                link={botLink}
              />
              <BackButton 
                onClick={() => {
                  navigate('/')
                  setSelectedCurr('login')
                }}
              />
            </Panel>
          </Tab>
        </Tabs>
      </div>
      <div className='w-full flex flex-col text-center cursor-pointer'>
        <a 
          className='text-[var(--link\_color)]'
          href={process.env.REACT_APP_RETURN_URL}
        >
          Return to App
        </a>
      </div>
      {
        error ? 
        <div className='w-full flex flex-col'>
          <div className='text-red-500 text-sm font-bold text-center'>
            {error}
          </div>
        </div> : null
      }
    </div>
  )
}
