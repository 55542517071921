import React, { ReactNode } from "react";

const Panel: React.FC<{ children: ReactNode }> = ({children}) => {
  return (
    <div className="flex flex-col gap-8 w-full h-fit">
      {children}
    </div>
  )
}

export default Panel