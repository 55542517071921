import React from "react";

const Title: React.FC<{title: string}> = ({title}) => {

  return (
    <div className='flex w-full justify-center text-2xl text-white'>
      {title}
    </div>
  )
}

export default Title