import React, { useState } from 'react'
import { Button, Tab, Tabs } from '@nextui-org/react'
import { delay, formatNumberWithSpacesShort } from '../../utils/utils'

const Payment: React.FC<{
  profile: {
    id: number
    name: string | null
    avatar: string | null
    premium: boolean
  } | null
  fetcher: Function
  tonRate: number
  usdt: number
  cbc: number
}> = ({ profile, fetcher, tonRate, usdt, cbc }) => {

  const [selectedCurr, setSelectedCurr] = useState('ton')
  const [walletError, setWalletError] = useState<string | null>(null)

  const paymentMethods = selectedCurr === 'ton' ? ['cryptobot', 'ton'] : ['cryptobot']

  const walletAction = async (item: string, curr: string) => {
    const res = await fetcher(item, curr)
    console.log(res)
    if (!res.success) {
      setWalletError(res.error)
    }
  }

  return (
    <>
      {
        profile && usdt !== 0 ? 
        <div className='flex flex-col gap-2'>
          <Tabs
            aria-label="Choose currency"
            classNames={{
              tabList: "w-full rounded-[44px] p-[2px] bg-[#080A23]",
              tab: "w-1/2 px-0 h-fit py-0 data-[selected=false]:bg-[#080A23] data-[selected=true]:bg-[#19192B]",
              cursor: "bg-[#19192B] p-1 rounded-[40px]",
              panel: "px-0"
            }}
            onSelectionChange={(key) => setSelectedCurr(key as string)}
            selectedKey={selectedCurr}
          >
            <Tab key="ton" title={
              <p className='BuildingsTab--name py-2.5 text-sm group-data-[selected=true]:font-semibold text-white'>
                TON
              </p>
            }>
            <div className='flex flex-row w-full justify-center items-center'>
              <img width={28} height={28} className='object-contain rounded-full' src={require("../../assets/crypto/ton.png")} alt="" />
              <p className='text-2xl/7 font-bold text-white ml-2 text-nowrap !font-sans'>
                {formatNumberWithSpacesShort((usdt ?? 0) / tonRate, 3)} TON
              </p>
              <p className='text-2xl/7 text-[var(--secondary\_hint)] ml-2 text-nowrap !font-sans'>
                = ${usdt ?? 0}
              </p>
            </div>
            </Tab>
            <Tab key="usdt" title={
              <p className='BuildingsTab--name py-2.5 text-sm group-data-[selected=true]:font-semibold text-white'>
                USDT
              </p>
            }>
              <div className='flex flex-row w-full justify-center items-center'>
                <img width={28} height={28} className='object-contain rounded-full' src={require("../../assets/crypto/usdt.png")} alt="" />
                <p className='text-2xl/7 font-bold text-white ml-2 text-nowrap !font-sans'>
                  {formatNumberWithSpacesShort((usdt ?? 0), 3)} USDT
                </p>
              </div>
            </Tab>
          </Tabs>
          <div className='flex justify-center w-full gap-4'>
          {
            paymentMethods.map(item =>
              <Button
                className='opacity-100 bg-transparent p-0 w-fit h-fit flex-col gap-2 !outline-none'
                onClick={() => walletAction(item, selectedCurr)}
                type='submit'
                key={item + '_payment'}
              >
                <img width={60} height={60} className='object-contain rounded-[19px]' src={require(`../../assets/crypto/${item}.png`)} alt="" />
                <p className='text-sm font-semibold text-white capitalize'>
                  {item === 'ton' ? 'Tonconnect' : item}
                </p>
              </Button>)
          }
          </div>
        </div> : (
          profile ? 
            <div className='w-full flex flex-col'>
              <div className='text-red-500 text-sm font-bold text-center'>
                Can't buy 0 CBC
              </div>
            </div> : null
        )
      }
      {
        walletError ? 
          <div className='w-full flex flex-col'>
            <div className='text-red-500 text-sm font-bold text-center'>
              { walletError }
            </div>
          </div> : null
      }
    </>
  )
}

export default Payment